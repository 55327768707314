import { FC } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useGetFcos } from '../../../api/fcos';
import { MaterializationStatusState } from '../../../api/gql/graphql';
import { useGetJobDetails } from '../../../api/jobs';
import { FCOType, FeatureViewFCO, FeatureViewFCOType, WorkspaceFCOContainer } from '../../../core/types/fcoTypes';
import IdUtils from '../../../utils/id-utils';
import { secondsToHumanFriendlyString } from '../../../utils/misc-utils';
import { FeatureFlags, useUserSettings } from '../../context/UserSettingsContext';

import { typeLabelMap } from './FeatureViewTypeBadge';

import {
  Card,
  ColumnLayout,
  EmptyPrompt,
  EmptyValue,
  EnabledDisabledBadge,
  FCOCard,
  FCOIconAndName,
  FlexGroupWrapper,
  FlexItem,
  JobStatusBadge,
} from '@tecton/ComponentRedesign';
import { TaskType } from '../../../types/tecton_proto/materialization/spark_cluster';
import { taskTypeLabelMap } from './TaskTypeBadge';
import { TaskAttemptDetails } from '../../../types/tecton_proto/metadataservice/metadata_service';

interface ImplicitConfigClusterCardProps {
  attemptDetails?: TaskAttemptDetails;
}

const FeatureViewDeleted = () => {
  return <span>Not Available: Feature View deleted</span>;
};

const ImplicitConfigClusterCard: FC<ImplicitConfigClusterCardProps> = ({ attemptDetails }) => {
  if (!attemptDetails) {
    return (
      <Card>
        <EmptyPrompt title={'Cluster Configuration'} body={<FeatureViewDeleted />} />
      </Card>
    );
  }

  const { cluster_config, environment } = attemptDetails;

  const items = [
    {
      title: 'Instance Type',
      content: cluster_config?.instance_type ?? <>No Instance Type Specified</>,
    },
    {
      title: 'Environment',
      content: environment ?? <>No Environment Specified</>,
    },
  ];

  return <FCOCard title="Cluster Configuration" items={items} />;
};

const JobDetailMetadata: FC = () => {
  const { jobId } = useParams();
  const { featureEnabled } = useUserSettings();
  const canShowRiftUrl = featureEnabled(FeatureFlags.SHOW_JOB_DETAIL_EXTERNAL_URL);
  const { data: jobDetailData, isSuccess: materializationStatusIsSuccess } = useGetJobDetails(jobId ?? '');

  const { data: featureViewData, isSuccess: featureViewIsSuccess } = useGetFcos<FeatureViewFCO>(
    jobDetailData?.fco_locator?.workspace ?? '',
    {
      enabled: materializationStatusIsSuccess, // we wait for the Job Details request to finish since we need the workspace and FV name
      select: (data: WorkspaceFCOContainer) => {
        return data?.featureViews.find((fv) => {
          const fcoId = IdUtils.toStringId(jobDetailData?.fco_locator?.id);
          // Let's find the feature view from this workspace
          return fv?.id === fcoId;
        });
      },
    }
  );

  if (!materializationStatusIsSuccess || !featureViewIsSuccess) {
    return (
      <>
        <EmptyPrompt variant="loading" title={'Loading Job'} body={<></>} />
      </>
    );
  }

  const attemptStatus = jobDetailData?.attempt_details?.attempt_status;

  // Replace these with actual values
  const jobStatus =
    attemptStatus?.materialization_state ?? MaterializationStatusState.MaterializationStatusStateUnspecified;
  const jobType = taskTypeLabelMap[jobDetailData?.attempt_details?.task_type ?? TaskType.UNKNOWN];

  const durationInSeconds = parseInt(attemptStatus?.duration?.toString() ?? '0');

  const materializationWindowStart = attemptStatus?.window_start_time ?? '';
  const materializationWindowEnd = attemptStatus?.window_end_time ?? '';

  // TODO: delete this and when we migrate this page over to graphql
  const jobDetailAttributes = [
    {
      title: 'Job Status',
      content: <>{jobStatus ? <JobStatusBadge status={jobStatus as MaterializationStatusState} /> : <EmptyValue />}</>,
    },
    {
      title: 'Job Type',
      content: jobType,
    },
    { title: 'Duration', content: secondsToHumanFriendlyString(durationInSeconds) },
    {
      title: 'Feature View Name',
      content: (
        <>
          {featureViewData ? (
            <Link to={`/repo/${featureViewData.workspace}/features/${featureViewData?.name}`}>
              <FCOIconAndName name={featureViewData?.name ?? ''} type={FCOType.FEATURE_VIEW} isModified={false} />
            </Link>
          ) : (
            <>Not Available: Feature View Deleted</>
          )}
        </>
      ),
    },
    {
      title: 'Feature View Type',
      content: typeLabelMap[(featureViewData?.featureViewType as FeatureViewFCOType) ?? FeatureViewFCOType.UNKNOWN],
    },
    {
      title: 'Materialization Window Start',
      content: materializationWindowStart,
    },
    {
      title: 'Materialization Window End',
      content: materializationWindowEnd,
    },
    {
      title: 'Online Materialization',
      content: <EnabledDisabledBadge enabled={featureViewData?.isOnlineMaterializationEnabled ?? false} />,
    },
    {
      title: 'Offline Materialization',
      content: <EnabledDisabledBadge enabled={featureViewData?.isOfflineMaterializationEnabled ?? false} />,
    },
    ...(canShowRiftUrl
      ? [
          {
            title: 'Rift Job',
            content: jobDetailData.attempt_details?.rift_instance_url ? (
              <Link to={jobDetailData.attempt_details?.rift_instance_url} target="_blank">
                Job Detail
              </Link>
            ) : (
              <>n/a</>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <FlexGroupWrapper gap="l" direction="column">
        <FlexItem>
          <ColumnLayout>
            <FCOCard title="Job Details" items={jobDetailAttributes} />
            <ImplicitConfigClusterCard attemptDetails={jobDetailData?.attempt_details} />
          </ColumnLayout>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default JobDetailMetadata;
