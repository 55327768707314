/* eslint-disable jsx-a11y/no-autofocus */
import styled from '@emotion/styled';
import { FC, useState } from 'react';

import {
  Avatar,
  Button,
  CenterContentLayout,
  DeprecatedText,
  FlexGroupWrapper,
  FlexItem,
  Flyout,
  Icon,
  SearchInput,
  Tooltip,
  useTectonTheme,
} from '@tecton/ComponentRedesign';
import { AvailableWorkspaces, Workspace } from '@tecton/ComponentRedesign/SharedComponentTypes';

// @svc
import DevelopmentWorkspace from '@svg/development-workspace.svg';
import LiveWorkspace from '@svg/live-workspace.svg';
import Lock from '@svg/lock.svg';
import PlusIcon from '@svg/plus.svg';
import HowToCreateWorkspace from '../../redesign/workspace/HowToCreateWorkspace';
import { IconType } from '../ComponentRedesign/lib/v1/Icon';

interface TectonWorkspaceSelectorProps {
  workspaces: AvailableWorkspaces;
  onWorkspaceSelected: (workspace: Workspace) => void;
  hideDevelopmentSection?: boolean;
}

const Wrapper = styled.div<{ isAccessible: boolean }>`
  display: grid;
  cursor: ${({ isAccessible }) => (isAccessible ? 'pointer' : 'unset')};
  grid-template-columns: 35px 164px;
  line-height: 16px;
  gap: 16px;
  align-items: middle;
  padding: ${({ theme }) => theme.padding.s} 0px;
  border-radius: ${({ theme }) => theme.border.radius.small};

  :hover {
    background-color: ${({ theme, isAccessible }) => (!isAccessible ? 'unset' : theme.colors.backgroundPrimary)};
    padding: ${({ theme }) => theme.padding.s};
    position: relative;
    right: ${({ theme }) => theme.padding.s};
    width: 355px;
  }
`;

const NameAndType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NameContainer = styled.div<{ isAccessible: boolean }>`
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 287px;
  color: ${({ theme, isAccessible }) => (!isAccessible ? theme.colors.subduedText : '')};
`;

export const SingleWorkspace: FC<{
  workspace: Workspace;
  onWorkspaceSelected: (workspace: Workspace) => void;
}> = ({ workspace, onWorkspaceSelected }) => {
  const { theme } = useTectonTheme();

  const backgroundColor = !workspace.isAccessible
    ? theme.colors.body
    : workspace.workspaceType === 'Live'
    ? theme.colors.warning
    : theme.colors.behindTextSuccess;

  const icon: IconType = !workspace.isAccessible
    ? 'Lock'
    : workspace.workspaceType === 'Live'
    ? 'ProductionWorkspace'
    : 'DevelopmentWorkspace';

  return (
    <Wrapper
      isAccessible={workspace.isAccessible}
      onClick={() => {
        if (workspace.isAccessible) {
          onWorkspaceSelected(workspace);
        }
      }}
    >
      {workspace.isAccessible ? (
        <Avatar name={workspace.name} type="icon" size="l" square icon={icon} backgroundColor={backgroundColor} />
      ) : (
        <>
          <Tooltip
            trigger={
              <>
                <Avatar
                  name={workspace.name}
                  type="icon"
                  size="l"
                  square
                  icon={icon}
                  backgroundColor={backgroundColor}
                />
              </>
            }
            content="Not Accessible"
          />
        </>
      )}

      <NameAndType>
        {workspace.isAccessible ? (
          <NameContainer isAccessible={workspace.isAccessible}>{workspace.name}</NameContainer>
        ) : (
          <>
            <Tooltip
              trigger={
                <>
                  <NameContainer isAccessible={workspace.isAccessible}>{workspace.name}</NameContainer>
                </>
              }
              content="Not Accessible"
            />
          </>
        )}
      </NameAndType>
    </Wrapper>
  );
};

const Options = styled.div`
  padding: ${({ theme }) => theme.padding.s} ${({ theme }) => theme.padding.l};
  overflow: auto;
  max-height: 45vh;
`;

const GroupHeading = styled.div`
  color: ${({ theme }) => theme.colors.subduedText};
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  line-height: ${({ theme }) => theme.font.lineHeights.xs};
  font-weight: ${({ theme }) => theme.font.weight.regular};
`;

const WorkspaceTypeGroup = styled.div`
  margin: 24px 0px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
`;

const CreateWorkspaceWrapper = styled.div`
  margin-top: 30px;
`;

const NoSearchResult = ({ filterValue }: { filterValue: string }) => {
  const [IsFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);

  return (
    <CenterContentLayout>
      {IsFlyoutOpen && (
        <>
          <Flyout
            title={<>How to create a workspace</>}
            onClose={() => {
              setIsFlyoutOpen(false);
            }}
          >
            <HowToCreateWorkspace />
          </Flyout>
        </>
      )}
      <FlexGroupWrapper gap="m">
        <FlexItem>
          <DeprecatedText fontSize="l" fontWeight="medium">
            No Results
          </DeprecatedText>
        </FlexItem>
        <FlexItem>
          <DeprecatedText fontSize="xs" fontWeight="regular">
            There are no workspaces with that name in ‘{filterValue}’. Edit your search or create a new workspace.
          </DeprecatedText>
        </FlexItem>
      </FlexGroupWrapper>

      <CreateWorkspaceWrapper>
        <Button
          variant="emptyAction"
          label={'Create Workspace'}
          iconLeft={PlusIcon}
          onClick={() => {
            setIsFlyoutOpen(true);
          }}
        />
      </CreateWorkspaceWrapper>
    </CenterContentLayout>
  );
};

const TectonWorkspaceSelectorResults: FC<{
  workspaces: AvailableWorkspaces;
  filterValue: string;
  onWorkspaceSelected: (workspace: Workspace) => void;
  hideDevelopmentSection?: boolean;
}> = ({ workspaces, onWorkspaceSelected, filterValue, hideDevelopmentSection = false }) => {
  const hasNoResult = [workspaces.recent.length, workspaces.live.length, workspaces.development.length].every(
    (value) => value === 0
  );

  return (
    <Options>
      {hasNoResult ? (
        <>
          <NoSearchResult filterValue={filterValue} />
        </>
      ) : (
        <>
          {workspaces.recent?.length > 0 && (
            <WorkspaceTypeGroup>
              <GroupHeading>Recent</GroupHeading>
              {workspaces.recent.map((workspace) => {
                return <SingleWorkspace workspace={workspace} onWorkspaceSelected={onWorkspaceSelected} />;
              })}
            </WorkspaceTypeGroup>
          )}

          <WorkspaceTypeGroup>
            <GroupHeading>Live</GroupHeading>
            {workspaces.live.map((workspace) => {
              return <SingleWorkspace workspace={workspace} onWorkspaceSelected={onWorkspaceSelected} />;
            })}
          </WorkspaceTypeGroup>
          {!hideDevelopmentSection && (
            <WorkspaceTypeGroup>
              <GroupHeading>Development</GroupHeading>
              {workspaces.development.map((workspace) => {
                return <SingleWorkspace workspace={workspace} onWorkspaceSelected={onWorkspaceSelected} />;
              })}
            </WorkspaceTypeGroup>
          )}
        </>
      )}
    </Options>
  );
};

const SelectorContainer = styled.div`
  height: 90vh;
  width: 372px;
`;

const DialogHeader = styled.div`
  width: 372px;
  padding: ${({ theme }) => theme.padding.l} ${({ theme }) => theme.padding.l} 0px ${({ theme }) => theme.padding.l};
`;

const DialogTitle = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.m};
  line-height: ${({ theme }) => theme.font.lineHeights.m};
  color: ${({ theme }) => theme.colors.title};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-style: normal;
`;

const TectonWorkspaceSelector: FC<TectonWorkspaceSelectorProps> = ({
  workspaces,
  onWorkspaceSelected,
  hideDevelopmentSection = false,
}) => {
  const [filterValue, setFilterValue] = useState('');

  const filteredWorkspaces = {
    recent: workspaces.recent.filter((workspace) => workspace.name.includes(filterValue.toLowerCase())),
    live: workspaces.live.filter((workspace) => workspace.name.includes(filterValue.toLowerCase())),
    development: workspaces.development.filter((workspace) => workspace.name.includes(filterValue.toLowerCase())),
  };
  return (
    <SelectorContainer>
      <DialogHeader>
        <FlexGroupWrapper gap="l" direction="column">
          <FlexItem>
            <DialogTitle>Workspaces</DialogTitle>
          </FlexItem>
          <FlexItem>
            <SearchInput
              autoFocus
              onChange={(event) => {
                setFilterValue(event.target.value);
              }}
              value={filterValue}
              canUseFormRow={false}
              placeholder="Search workspaces..."
            />
          </FlexItem>
        </FlexGroupWrapper>
      </DialogHeader>
      <TectonWorkspaceSelectorResults
        workspaces={filteredWorkspaces}
        onWorkspaceSelected={onWorkspaceSelected}
        filterValue={filterValue}
        hideDevelopmentSection={hideDevelopmentSection}
      />
    </SelectorContainer>
  );
};

export default TectonWorkspaceSelector;
