import React, { FC } from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  FloatingOverlay,
} from '@floating-ui/react';

import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { Button, FlexItem, FlexGroup, Text } from '.';
import { createPortal } from 'react-dom';

interface FlyoutProps {
  title: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const slideIn = keyframes`
  0% {
    transform: scale(0,1) translateX(100vw); /* Off-screen to the right */
  }
  100% {
    transform: scale(1,1); /* Move to the right edge of the viewport */
  }
`;

const FlyoutContainer = styled.div`
  position: absolute;
  width: 50%;

  right: 0;
  overflow: visible;

  z-index: ${({ theme }) => theme.v1.layers.flyout};

  padding: 0;
`;

const TopRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.v1.colors.border.default};
  padding: ${({ theme }) => {
    return `${theme.v1.size['4']}px ${theme.v1.size['6']}px`;
  }};
  width: 100%;
  height: auto;
`;

const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  padding: ${({ theme }) => theme.v1.size[6]}px;
  align-self: stretch;
  flex: 1;
  overflow: scroll;
`;

const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.v1.colors.background.default};
  padding: ${({ theme }) => {
    return `${theme.v1.size['4']}px ${theme.v1.size['6']}px`;
  }};
  width: 100%;
  height: auto;
  margin: 0;
`;

const FlyoutAnimation = styled.div`
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  box-shadow: ${({ theme }) => theme.v1.shadow.l};
  height: 100vh;
  width: 100%;
  overflow: scroll;

  animation: ${({ theme }) => {
    return css`
      ${slideIn} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.enter} forwards
    `;
  }};
`;

const Flyout: FC<FlyoutProps> = ({ title, content, footer, isOpen, setIsOpen }) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  });
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([click, dismiss, role]);

  return (
    <>
      {isOpen &&
        createPortal(
          <FloatingOverlay
            lockScroll
            style={{
              zIndex: 9999,
              overflow: 'hidden',
              top: 0,
              left: 0,
            }}
            data-testid="flyout"
          >
            <FloatingFocusManager context={context}>
              <div ref={refs.setFloating} {...getFloatingProps()}>
                <FlyoutContainer>
                  <FlyoutAnimation>
                    <FlexGroup
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      padding={'0'}
                      gapSize="0"
                    >
                      <TopRow>
                        <FlexGroup justifyContent="space-between" alignItems="center" padding={'0'} gapSize="4">
                          <Text element="h3">{title}</Text>
                          <Button type="ghost" icon={'Exit'} onClick={() => setIsOpen(false)} />
                        </FlexGroup>
                      </TopRow>

                      <FlexItem grow={'1'} shrink={'1'}>
                        <ContentContainer>{content}</ContentContainer>
                      </FlexItem>

                      {footer && (
                        <FooterContainer>
                          <FlexItem shrink={'0'}>
                            <FlexGroup justifyContent="flex-end" gapSize={'3'} padding={'0'}>
                              {footer}
                            </FlexGroup>
                          </FlexItem>
                        </FooterContainer>
                      )}
                    </FlexGroup>
                  </FlyoutAnimation>
                </FlyoutContainer>
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>,
          document.body
        )}
    </>
  );
};

export default Flyout;
