import styled from '@emotion/styled';
import ReactDiffViewer from 'react-diff-viewer';
import {
  AsymmetricBodyLayout,
  AvatarAndName,
  Breadcrumbs,
  Card,
  Code,
  DescriptionList,
  EmptyPrompt,
  EmptyValue,
  FCOIconAndName,
  FlexGroup,
  FlexGroupWrapper,
  FlexItem,
  Flyout,
  HeaderLayout,
  Icon,
  Icons,
  IconTypes,
  Table,
  Tabs,
  Text,
  TruncateText,
  ViewLayout,
} from '@tecton/ComponentRedesign';
import { Callout } from '@tecton/ComponentRedesign/lib/v1';

import moment from 'moment';
import React, { type FC, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useGetFcos } from '../../../api/fcos';
import { useGetQueryStateUpdateResponse, useGetStateUpdatePlanSummary } from '../../../api/plan-apply-log';
import { FCO, FCOFields, FCOType, WorkspaceFCOContainer } from '../../../core/types/fcoTypes';
import { StateUpdatePlanSummaryType } from '../../../feature/plan-summary/PlanSummaryTransform';
import { FcoPropertyRenderingType } from '../../../types/tecton_proto/args/diff_options';
import {
  FcoPropertyDiff,
  StateUpdatePlanSummary,
  StateUpdatePlanSummaryDiff,
} from '../../../types/tecton_proto/data/state_update';
import { TectonDateTimeFormat } from '../../@tecton/ComponentRedesign/utils';
import { useUserSettings } from '../../context/UserSettingsContext';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';
import processMaterializationInfo from './processMaterializationInfo';
import {
  SingleDiffSummaryLine,
  filterPlanDataByType,
  materializationTypesForFeatureView,
  processDiffItems,
} from './utils';
import useItemExpansionState from '../../../feature/plan-summary/useItemExpansionState';
import { ChevronDown, ChevronRight, FeatureView } from '@tecton/ComponentRedesign/Icons';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import { ActionBadge } from './ActionBadge';

interface FCOTypeTableProps {
  items: StateUpdatePlanSummaryDiff[];
  matchingTypes: string[];
  fcoData: WorkspaceFCOContainer;
}

interface FCOTypeColumn {
  fcoType: string | undefined;
}

// TITLE X-Small
// These need to be replaced by actual text styles / text component
const CardSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.font.headingLineHeights['5']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: ${({ theme }) => theme.padding.l};
  padding-bottom: ${({ theme }) => theme.padding.s};
`;

// TITLE x-small
// These need to be replaced by actual text styles / text component
const TableHeader = styled.div`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.font.headingLineHeights['5']};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: ${({ theme }) => theme.padding.l};
`;

// Fixes flex group to allow content below tabs to scroll independently
// These might be better off in a separate styled components file.
const FixedHeightFlexGroup = styled(FlexGroup)`
  height: 100%;
  overflow: hidden;
`;

const ScrollWithinFlex = styled.div`
  padding-top: ${({ theme }) => theme.padding.l};
  width: 100%;
  height: calc(100%);
`;

const actionColumn = {
  name: 'Action',
  render: (item: StateUpdatePlanSummaryDiff) => <ActionBadge type={item.type} />,
  width: '132px',
};

const TruncateNameColumn = styled.div`
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  .tooltipName {
    max-width: 250px;
  }

  .euiToolTipAnchor {
    justify-content: flex-start;
  }
`;

const nameColumn = (fcoData: Record<string, FCO>, fcoType: FCOType, workspace: string) => {
  const linkToMap: Record<FCOType, string> = {
    [FCOType.DATA_SOURCE]: `../${workspace}/data-sources/`,
    [FCOType.ENTITY]: `../${workspace}/entities/`,
    [FCOType.FEATURE_VIEW]: `../${workspace}/features/`,
    [FCOType.FEATURE_SERVICE]: `../${workspace}/feature-services/`,
    [FCOType.TRANSFORMATION]: `../${workspace}/transformations/`,
    [FCOType.DATASET]: `../${workspace}/datasets/`,
    [FCOType.UNKNOWN]: `../${workspace}/home/`,
  };
  return {
    name: 'Applied to',
    render: (item: StateUpdatePlanSummaryDiff) => {
      const matchingFv = fcoData[item?.name ?? ''];

      if (matchingFv) {
        return (
          <TruncateNameColumn>
            <Link to={`${linkToMap[fcoType]}${item.name}`}>
              <FCOIconAndName type={fcoType} name={item.name ?? ''} description={matchingFv[FCOFields.DESCRIPTION]} />
            </Link>
          </TruncateNameColumn>
        );
      }
      return (
        <TruncateNameColumn>
          <FCOIconAndName type={fcoType} isModified name={item.name ?? ''} />
        </TruncateNameColumn>
      );
    },
    width: '300px',
  };
};

const changeColumn = {
  name: 'Changes',
  render: (item: StateUpdatePlanSummaryDiff) => <PlanChanges item={item} />,
  width: '5em',
};

const emptyColumn = {
  name: <></>,
  render: () => <></>,
};

const PlanChanges: FC<{ item: StateUpdatePlanSummaryDiff }> = ({ item }) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);
  const { itemIdToExpandedRowMap, expandItemWithContent, collapseItem } = useItemExpansionState();
  const { theme } = useTectonTheme();

  const filteredDiffs = item.diffs?.filter(
    (diff) => diff.rendering_type !== FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_HIDDEN
  );
  const count = filteredDiffs?.length ?? 0;

  if (!count) {
    return <EmptyValue />;
  }

  const toggleCodeDiffRow = (rowIsOpen: boolean, row: FcoPropertyDiff) => {
    if (rowIsOpen) {
      collapseItem(row?.property_name ?? '');
      return;
    }

    expandItemWithContent(
      row.property_name ?? '',
      <ReactDiffViewer oldValue={row.val_existing} newValue={row.val_declared} splitView={true} />
    );
  };

  const columns = [
    {
      name: ' ',
      width: theme.spacing['4xl'],
      render(diff: FcoPropertyDiff) {
        if (diff.rendering_type !== FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_PYTHON) {
          return false;
        }

        const rowIsOpen = !!itemIdToExpandedRowMap[diff?.property_name ?? ''];

        return <>{rowIsOpen ? <Icon type={ChevronDown} /> : <Icon type={ChevronRight} />}</>;
      },
    },
    {
      name: 'Property',
      render: (diff: FcoPropertyDiff) => <>{diff.property_name}</>,
    },
    {
      name: 'From',
      render: (diff: FcoPropertyDiff) => {
        if (diff.rendering_type === FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_PYTHON) {
          return <>View Details</>;
        }

        return <>{diff.val_existing}</>;
      },
    },
    {
      name: 'To',
      render: (diff: FcoPropertyDiff) => {
        if (diff.rendering_type === FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_PYTHON) {
          return false;
        }

        return <span style={{ textAlign: 'right' }}>{diff.val_declared}</span>;
      },
    },
  ];

  const modalTitle = count === 1 ? 'Property Change' : `${count} Property Changes`;

  return (
    <>
      <Link
        onClick={() => {
          setIsFlyoutOpen(true);
        }}
        to={''}
      >
        <FlexGroup gutterSize="xs" alignItems="center">
          <FlexItem grow={false}>{Icons[IconTypes.CODE_CHANGES]}</FlexItem>
          <FlexItem grow={false}>
            <Text>{count}</Text>
          </FlexItem>
        </FlexGroup>
      </Link>
      {isFlyoutOpen && (
        <Flyout
          title={
            <>
              {modalTitle}
              {item.name && (
                <FlexGroup gutterSize="xs" alignItems="center" style={{ marginTop: theme.spacing.lg }}>
                  <Icon type={FeatureView} />
                  <Text>{item.name}</Text>
                </FlexGroup>
              )}
            </>
          }
          onClose={() => {
            setIsFlyoutOpen(false);
          }}
        >
          <Table
            itemId="property_name"
            itemIdToExpandedRowMap={itemIdToExpandedRowMap}
            items={filteredDiffs!}
            columns={columns}
            layout="auto"
            allowWordBreak
            rowProps={(row) => ({
              style: {
                cursor:
                  row.rendering_type === FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_PYTHON
                    ? 'pointer'
                    : 'inherit',
              },
              onClick: () => {
                if (row.rendering_type !== FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_PYTHON) return false;

                const rowIsOpen = !!itemIdToExpandedRowMap[row.property_name ?? ''];

                toggleCodeDiffRow(rowIsOpen, row);
              },
            })}
          />
        </Flyout>
      )}
    </>
  );
};

const CHANGES_TYPE_MAP: Record<string, string> = {
  'Feature Table': 'Table',
  'Feature Service': 'Service',
  'Realtime Feature View': 'Realtime',
  'Batch Feature View': 'Batch',
  'Batch Data Source': 'Batch',
  'Stream Feature View': 'Stream',
  'Stream Data Source': 'Stream',
  'Stream Feature View with Push Source': 'Stream with Push Source',
};

const TypeColumn: FC<FCOTypeColumn> = ({ fcoType }) => {
  if (!fcoType) return <EmptyValue />;

  const changeType = CHANGES_TYPE_MAP[fcoType] || fcoType;

  return <TruncateText>{changeType}</TruncateText>;
};

const FeatureViewsTable: FC<FCOTypeTableProps> = ({ items, matchingTypes, fcoData }) => {
  const filteredItems = filterPlanDataByType(items, matchingTypes);
  const { workspace } = useParams();

  if (filteredItems.length === 0) {
    return <></>;
  }

  const columns = [
    actionColumn,
    nameColumn(fcoData.featureViewsNamesMap, FCOType.FEATURE_VIEW, workspace ?? ''),
    changeColumn,
    {
      name: 'Type',
      width: '110px',
      render: (item: StateUpdatePlanSummaryDiff) => <TypeColumn fcoType={item.fco_type} />,
    },
    {
      name: 'Materialization',
      render: (item: StateUpdatePlanSummaryDiff) => {
        if (
          item.type === 'DELETE' ||
          (item.fco_type !== 'Stream Feature View' && item.fco_type !== 'Batch Feature View')
        ) {
          return <EmptyValue />;
        }

        const summaries = materializationTypesForFeatureView(item);
        if (summaries.length === 0) {
          return <>No Materialization</>;
        }

        return <TruncateText>{summaries.join(', ')}</TruncateText>;
      },
    },
  ];

  return (
    <>
      <TableHeader>Feature Views & Tables</TableHeader>
      <Table items={filteredItems} columns={columns} layout="fixed" />
    </>
  );
};

const FeatureServicesTable: FC<FCOTypeTableProps> = ({ items, matchingTypes, fcoData }) => {
  const filteredItems = filterPlanDataByType(items, matchingTypes);
  const { workspace } = useParams();

  if (filteredItems.length === 0) {
    return <></>;
  }

  const columns = [
    actionColumn,
    nameColumn(fcoData.featureServicesNamesMap, FCOType.FEATURE_SERVICE, workspace ?? ''),
    changeColumn,
    emptyColumn,
    emptyColumn,
  ];

  return (
    <>
      <TableHeader>Feature Services</TableHeader>
      <Table items={filteredItems} columns={columns} layout="fixed" />
    </>
  );
};

const DataSourcesTable: FC<FCOTypeTableProps> = ({ items, matchingTypes, fcoData }) => {
  const filteredItems = filterPlanDataByType(items, matchingTypes);
  const { workspace } = useParams();

  if (filteredItems.length === 0) {
    return <></>;
  }

  const columns = [
    actionColumn,
    nameColumn(fcoData.dataSourcesNamesMap, FCOType.DATA_SOURCE, workspace ?? ''),
    changeColumn,
    {
      name: 'Type',
      width: '110px',
      render: (item: StateUpdatePlanSummaryDiff) => {
        return <TypeColumn fcoType={item.fco_type} />;
      },
    },
    emptyColumn,
  ];

  return (
    <>
      <TableHeader>Data Sources</TableHeader>

      <Table items={filteredItems} columns={columns} layout="fixed" />
    </>
  );
};

const EntitiesTable: FC<FCOTypeTableProps> = ({ items, matchingTypes, fcoData }) => {
  const filteredItems = filterPlanDataByType(items, matchingTypes);
  const { workspace } = useParams();

  if (filteredItems.length === 0) {
    return <></>;
  }

  const columns = [
    actionColumn,
    nameColumn(fcoData.entitiesNamesMap, FCOType.ENTITY, workspace ?? ''),
    changeColumn,
    emptyColumn,
    emptyColumn,
  ];

  return (
    <>
      <TableHeader>Entities</TableHeader>

      <Table items={filteredItems} columns={columns} layout="fixed" />
    </>
  );
};

const TransformationsTable: FC<FCOTypeTableProps> = ({ items, matchingTypes, fcoData }) => {
  const filteredItems = filterPlanDataByType(items, matchingTypes);
  const { workspace } = useParams();

  if (filteredItems.length === 0) {
    return <></>;
  }

  const columns = [
    actionColumn,
    nameColumn(fcoData.transformationsNamesMap, FCOType.TRANSFORMATION, workspace ?? ''),
    changeColumn,
    emptyColumn,
    emptyColumn,
  ];

  return (
    <>
      <TableHeader>Transformations</TableHeader>

      <Table items={filteredItems} columns={columns} layout="fixed" />
    </>
  );
};

const ApplySummary: FC<{ data: StateUpdatePlanSummary; fcoData: WorkspaceFCOContainer }> = ({ data, fcoData }) => {
  return (
    <>
      <FlexGroup direction="column">
        <FeatureViewsTable
          items={data.diff_items ?? []}
          matchingTypes={[
            'Feature Table',
            'Realtime Feature View',
            'Batch Feature View',
            'Stream Feature View',
            'Stream Feature View with Push Source',
            'Realtime Feature View',
          ]}
          fcoData={fcoData}
        />
        <FeatureServicesTable items={data.diff_items ?? []} matchingTypes={['Feature Service']} fcoData={fcoData} />
        <DataSourcesTable
          items={data.diff_items ?? []}
          matchingTypes={['Stream Data Source', 'Batch Data Source', 'Push Source']}
          fcoData={fcoData}
        />
        <EntitiesTable items={data.diff_items ?? []} matchingTypes={['Entity']} fcoData={fcoData} />
        <TransformationsTable items={data.diff_items ?? []} matchingTypes={['Transformation']} fcoData={fcoData} />
      </FlexGroup>
    </>
  );
};

const CLIOutput: FC<{ commitId: string }> = ({ commitId }) => {
  const { workspace } = useUserSettings();

  const { data, isLoading } = useGetQueryStateUpdateResponse(commitId ?? '', workspace ?? '');

  if (isLoading) {
    return (
      <FlexGroup direction="column" alignItems="center">
        <FlexItem>
          <EmptyPrompt variant="loading" title={<>Loading Data</>} body={undefined} orientation="vertical" />
        </FlexItem>
      </FlexGroup>
    );
  }

  return (
    <>
      {data?.successful_plan_output?.string_output && (
        <Code code={data?.successful_plan_output?.string_output} language="bash" />
      )}
    </>
  );
};

const ApplyLogRightSide: FC<{ data: StateUpdatePlanSummary }> = ({ data }) => {
  const commitId = useLocation().pathname.split('/').reverse()[0];
  const { workspace } = useUserSettings();

  const { data: fcoData, isLoading: fcoIsLoading } = useGetFcos(workspace ?? '', {});

  const [tab, setTab] = useState('Apply Details');

  if (fcoIsLoading) {
    return (
      <FlexGroup direction="column" alignItems="center">
        <FlexItem>
          <EmptyPrompt variant="loading" title={<>Loading Data</>} body={undefined} orientation="vertical" />
        </FlexItem>
      </FlexGroup>
    );
  }

  return (
    <FixedHeightFlexGroup direction="column" gutterSize="none">
      <FlexItem grow={false}>
        <Tabs selectedTab={tab} tabs={['Apply Details', 'CLI Output']} setSelectedTab={setTab} />
      </FlexItem>

      <FlexItem style={{ overflow: 'auto' }}>
        <ScrollWithinFlex>
          {tab === 'Apply Details' && <ApplySummary data={data} fcoData={fcoData} />}
          {tab === 'CLI Output' && <CLIOutput commitId={commitId} />}
        </ScrollWithinFlex>
      </FlexItem>
    </FixedHeightFlexGroup>
  );
};

const ObjectChangeTableWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.padding.m};

  .euiTableRow:hover,
  .euiTableRow:hover > .euiTableRowCell {
    background-color: unset;
  }
`;

const ObjectChanges: FC<{ data: StateUpdatePlanSummary }> = ({ data }) => {
  const summary = processDiffItems(data.diff_items);

  return (
    <>
      <CardSubtitle>Object Changes</CardSubtitle>
      {summary.map((summaryItem) => {
        const items = summaryItem.items_by_status;

        const columns = [
          {
            name: summaryItem.fco_type,
            render: (itemSummary: SingleDiffSummaryLine) => {
              return <ActionBadge type={itemSummary.status} />;
            },
          },
          {
            name: <></>,
            render: (itemSummary: SingleDiffSummaryLine) => {
              return itemSummary.items.length;
            },
            width: '4em',
          },
        ];
        return (
          <ObjectChangeTableWrapper>
            <Table items={items} columns={columns} layout="fixed" />
          </ObjectChangeTableWrapper>
        );
      })}
    </>
  );
};

const MetadataCard: FC<{ data: StateUpdatePlanSummary }> = ({ data }) => {
  const metadataItems = [
    {
      title: 'Created By',
      description: <AvatarAndName name={data.created_by} />,
    },
    {
      title: 'Created at',
      description: TectonDateTimeFormat(moment(data.applied_at)),
    },
    {
      title: 'Applied By',
      description: <AvatarAndName name={data.applied_by} />,
    },
    {
      title: 'Workspace',
      description: <TruncateText>{data.workspace}</TruncateText>,
    },
    {
      title: 'SDK Version',
      description: <>{data.sdk_version}</>,
    },
  ];

  return (
    <Card title="Metadata">
      <>
        <FlexGroup direction="column">
          <FlexItem>
            <DescriptionList items={metadataItems} />
          </FlexItem>
          <FlexItem>
            <ObjectChanges data={data} />
          </FlexItem>
        </FlexGroup>
      </>
    </Card>
  );
};

const ApplyLogCommitDetailsCallout: FC<{ data?: StateUpdatePlanSummaryType; summaries: React.ReactNode[] }> = ({
  data,
  summaries,
}) => {
  if (summaries.length === 0) {
    return <></>;
  }

  const concattedSummary = summaries.reduce<React.ReactNode[]>((memo, entry, i, array) => {
    if (array.length === 1) {
      return [entry];
    } else {
      const separator = <span key={`separator-${i}`}>{i === array.length - 2 ? <>&nbsp;and</> : <>,</>}</span>;

      memo.push(entry);
      if (i < array.length - 1) {
        memo.push(separator);
      }

      return memo;
    }
  }, []);

  if (data?.hasApplied) {
    return (
      <Callout
        variant="success"
        title="Note"
        icon
        content={<>Tecton created&nbsp;{concattedSummary}&nbsp;when this plan was applied.</>}
      />
    );
  }

  return (
    <Callout
      variant="info"
      title="Note"
      icon
      content={
        <div>
          Tecton is running integration tests for this Tecton Apply request. If successful, it will create
          {concattedSummary}. To view the integration test jobs, visit the&nbsp;
          <Link to={`../../jobs`}>Jobs page</Link>.
        </div>
      }
    />
  );
};

const ApplyLogDetails: FC<{ data?: StateUpdatePlanSummaryType }> = ({ data }) => {
  const { batchMaterialization, streamMaterialization, backfillJobs } = processMaterializationInfo(
    data?.plan?.diff_items ?? []
  );

  const summaries: React.ReactNode[] = [];

  let backfillCount = 0;
  if (backfillJobs.length > 0) {
    backfillCount = backfillJobs.reduce<number>((memo, current) => {
      return memo + (current?.number_of_jobs ?? 0);
    }, 0);

    summaries.push(
      <span key="backfill">
        {' '}
        <>{backfillCount}</> backfill job
        {backfillCount > 1 ? 's' : ''}
      </span>
    );
  }

  if (batchMaterialization.length > 0) {
    summaries.push(
      <span key="batch">
        {' '}
        <>{batchMaterialization.length}</> recurring batch job
        {batchMaterialization.length > 1 ? 's' : ''}
      </span>
    );
  }

  if (streamMaterialization.length > 0) {
    summaries.push(
      <span key="stream">
        {' '}
        <>{streamMaterialization.length}</> recurring stream job
        {streamMaterialization.length > 1 ? 's' : ''}
      </span>
    );
  }

  if (!data?.plan) {
    return <></>;
  }

  return (
    <>
      {summaries.length > 0 ? (
        <>
          <FlexGroupWrapper gap={'l'} direction="column">
            <FlexItem>
              <ApplyLogCommitDetailsCallout data={data} summaries={summaries} />
            </FlexItem>
            <FlexItem>
              <AsymmetricBodyLayout
                leftSide={<MetadataCard data={data?.plan} />}
                rightSide={<ApplyLogRightSide data={data?.plan} />}
              />
            </FlexItem>
          </FlexGroupWrapper>
        </>
      ) : (
        <>
          {' '}
          <AsymmetricBodyLayout
            leftSide={<MetadataCard data={data?.plan} />}
            rightSide={<ApplyLogRightSide data={data?.plan} />}
          />
        </>
      )}
    </>
  );
};

const ApplyLogCommitDetails: FC = () => {
  const { workspaceDetails } = useUserSettings();

  const commitId = useLocation().pathname.split('/').reverse()[0];

  const { data, isLoading } = useGetStateUpdatePlanSummary(commitId);

  if (isLoading) {
    return (
      <FlexGroup direction="column" alignItems="center">
        <FlexItem>
          <EmptyPrompt variant="loading" title={<>Loading Data</>} body={undefined} orientation="vertical" />
        </FlexItem>
      </FlexGroup>
    );
  }

  const header = (
    <HeaderLayout
      breadcrumbs={
        <Breadcrumbs
          workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
          crumbs={[
            { label: <Link to={`/repo/${workspaceDetails?.name}/home/apply-log`}>Apply Log</Link> },
            { label: commitId },
          ]}
        />
      }
    />
  );
  return <ViewLayout header={header} body={<ApplyLogDetails data={data} />} />;
};

export default ApplyLogCommitDetails;
