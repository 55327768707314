import React, { useContext, useState } from 'react';

import { DataQualityMonitoringContext } from './DataQualityMetricsContext';
import { MaterializationMetricTypes } from './DataQualityMetricsTypes';
import { Popover, Selectable, Button } from '@tecton/ComponentRedesign';

export const DataQualityMetricSelector = () => {
  const context = useContext(DataQualityMonitoringContext);

  const metricOptions: { label: string; value: MaterializationMetricTypes; checked?: 'on' | 'off' }[] = [
    {
      label: 'Null Values Percentage',
      value: MaterializationMetricTypes.COUNT_NULLS,
    },
    {
      label: 'Zero Values Percentage',
      value: MaterializationMetricTypes.COUNT_ZEROS,
    },
  ];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<{ label: string; value: MaterializationMetricTypes }>(
    metricOptions.find((option) => option.value === context.selectedMetric) ?? metricOptions[0]
  );

  const metricLabel = selectedOption.label;

  return (
    <Popover
      button={
        <Button
          variant={'emptyAction'}
          label={metricLabel}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      }
      body={
        <Selectable
          height={`${metricOptions.length * 2 + 1}em`}
          width={'20em'}
          options={metricOptions.map((option) => {
            return {
              ...option,
              checked: option.label === selectedOption.label ? 'on' : undefined,
            };
          })}
          key={'metric-selector'}
          singleSelect
          changeCallback={(options) => {
            const newValue = options.filter((option) => option.checked === 'on')[0];
            setIsOpen(false);
            setSelectedOption(newValue);
            context.setSelectedMetric(newValue.value);
          }}
        />
      }
      isOpen={isOpen}
      closePopover={() => {
        setIsOpen(false);
      }}
    />
  );
};

export default DataQualityMetricSelector;
