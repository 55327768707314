import { useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router';

// Tecton
import FeatureViewMonitoring from './FeatureViewMonitoring';
import {
  MonitoringFeatureServiceRoutes,
  MonitoringRoutes,
  getMonitoringFeatureServiceGroupRoutes,
  getMonitoringRouteName,
} from './monitoringUtils';
import StreamIngestMonitoring from './StreamIngestMonitoring';

// @tecton
import { Breadcrumbs, HeaderLayout, Tabs, ViewLayout } from '@tecton/ComponentRedesign';
import { useUserSettings } from '../../context/UserSettingsContext';
import RealtimeServerMonitoring from './RealtimeServerMonitoring';

const MonitoringContainerOutlet = () => {
  /*
    This contains the header portion of the Feature view
    - Breadcrumbs
    - Tabs
    - Outlet to the content
  */

  const navigation = useNavigate();

  const location = useLocation();
  const featureRoutes = getMonitoringRouteName(location.pathname);
  const [selectedTab, setSelectedTab] = useState<MonitoringRoutes | MonitoringFeatureServiceRoutes>(featureRoutes);

  const breadcrumbs = <Breadcrumbs root="Monitoring" crumbs={[]} />;

  const routeNames = Object.keys(getMonitoringFeatureServiceGroupRoutes());

  const tabs = (
    <Tabs
      selectedTab={selectedTab}
      tabs={routeNames}
      setSelectedTab={(tab: MonitoringFeatureServiceRoutes) => {
        const routes = getMonitoringFeatureServiceGroupRoutes();
        navigation(routes[tab]);
        setSelectedTab(tab);
      }}
    />
  );

  const header = <HeaderLayout breadcrumbs={breadcrumbs} tabs={tabs} />;

  return <ViewLayout header={header} body={<Outlet />} />;
};

const MonitoringContainer = () => {
  const { isStreamingIngestEnabled } = useUserSettings();

  const monitoringRoutes = getMonitoringFeatureServiceGroupRoutes();
  return (
    <Routes>
      <Route element={<MonitoringContainerOutlet />}>
        <Route index element={<Navigate to={monitoringRoutes[`Feature View`]} replace />} />
        <Route path={monitoringRoutes[`Feature View`]} element={<FeatureViewMonitoring />} />
        <Route path={monitoringRoutes[`Realtime Server`]} element={<RealtimeServerMonitoring />} />
        {isStreamingIngestEnabled && (
          <Route path={monitoringRoutes[`Stream Ingest`]} element={<StreamIngestMonitoring />} />
        )}
      </Route>
    </Routes>
  );
};

export default MonitoringContainer;
