import styled from '@emotion/styled';
import { Card, EnabledDisabledBadge, Spacer } from '@tecton/ComponentRedesign';
import TectonFCOCard, { CardTable, TectonFCOListItem } from '@tecton/ComponentRedesign/FCOCard';
import TectonFCOIconAndName from '@tecton/ComponentRedesign/FCOIconAndName';
import EmptyValue from '@tecton/ComponentRedesign/lib/EmptyValue';
import Monospace from '@tecton/ComponentRedesign/lib/Monospace';
import TimeIntervalDisplay from '@tecton/ComponentRedesign/lib/TimeIntervalDisplay';
import { TectonDateTimeFormat } from '@tecton/ComponentRedesign/utils';
import moment from 'moment-timezone';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  EntityFCO,
  FCOType,
  FeatureViewFCO,
  FeatureViewFCOFields,
  OnlineStoreType,
} from '../../../core/types/fcoTypes';
import { OnlineBackfillLoadType } from '../../../types/tecton_proto/data/fv_materialization';
import { useUserSettings } from '../../context/UserSettingsContext';
import { useGetGlobalsForWebUi } from '../../../api/user';
import { KeyValueList } from '../../@tecton/ComponentRedesign/lib/v1';

export enum ConfigCardItemType {
  TYPE,
  TIMESTAMP_FIELD,
  STREAM_PROCESSING_MODE,
  SKIP_DEFAULT_EXPECTATIONS,
  SERVING_TTL,
  REQUEST_DATA_SOURCE_KEYS,
  ONLINE_STORE_TYPE,
  ONLINE_MATERIALIZATION,
  OFFLINE_STORE_TYPE,
  OFFLINE_MATERIALIZATION,
  MAX_DATA_SOURCE_DELAY,
  MAX_BACKFILL_INTERVAL,
  MATERIALIZATION_START_TIME,
  MATERIALIZATION_RUNTIME,
  MANUAL_TRIGGER_BACKFILL_END_TIME,
  JOIN_KEYS,
  INCREMENTAL_BACKFILL,
  FRESHNESS_MONITORING_ENABLED,
  FEATURE_START_TIME,
  EXPECTED_FRESHNESS,
  ENVIRONMENTS,
  ENTITIES,
  DATA_QUALITY_ENABLED,
  CACHE_MAX_AGE,
  BATCH_TRIGGER,
  BATCH_SCHEDULE,
  ALERT_EMAIL,
  AGGREGATION_SECONDARY_KEY,
  AGGREGATION_INTERVAL,
  PRIMARY_ENDPOINT,
  PUBLISH_FULL_FEATURES,
  PUBLISH_START_TIME,
  BATCH_COMPACTION_ENABLED,
  STREAM_TILING_ENABLED,
  ONLINE_BACKFILL_LOAD_TYPE,
}

const aggregationIntervalItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Aggregation Interval</>,
    content: fco[FeatureViewFCOFields.MATERIALIZATION_INTERVAL_IN_SECONDS] ? (
      <TimeIntervalDisplay
        interval={{ seconds: fco[FeatureViewFCOFields.MATERIALIZATION_INTERVAL_IN_SECONDS].toString() }}
      />
    ) : (
      <EmptyValue />
    ),
  };
};

const aggregationSecondaryKeyItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Aggregation Secondary Key</>,
    content: fco[FeatureViewFCOFields.TEMPORAL_AGGREGATE_SECONDARY_KEY] ? (
      <Monospace>{fco[FeatureViewFCOFields.TEMPORAL_AGGREGATE_SECONDARY_KEY]}</Monospace>
    ) : (
      <EmptyValue />
    ),
  };
};

const alertEmailItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Alert Email</>,
    content: <>{fco[FeatureViewFCOFields.ALERT_EMAIL] ? fco[FeatureViewFCOFields.ALERT_EMAIL] : <EmptyValue />}</>,
  };
};

const batchScheduleItem = (fco: FeatureViewFCO) => {
  const batchSchedule = fco[FeatureViewFCOFields.MATERIALIZATION_PARAMS].schedule_interval
    ? (fco[FeatureViewFCOFields.MATERIALIZATION_PARAMS].schedule_interval as string).replace('s', '')
    : undefined; // TODO Oct-18-2023: This is messy and should be unnecessary. Confirm that this is producing the correct values with Derek and refactor.

  return {
    title: <>Batch Schedule</>,
    content: <TimeIntervalDisplay interval={{ seconds: batchSchedule }} />,
  };
};

const batchTriggerItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Batch Trigger</>,
    content: <>{fco[FeatureViewFCOFields.BATCH_TRIGGER]}</>,
  };
};

const dataQualityItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Data Quality Enabled</>,
    content: <EnabledDisabledBadge enabled={fco[FeatureViewFCOFields.IS_DATA_QUALITY_ENABLED] ?? false} />,
  };
};

const environmentsConfigItem = (fco: FeatureViewFCO) => {
  if (fco[FeatureViewFCOFields.REALTIME_ENVIRONMENTS].length === 0) {
    return {
      title: <>Environments</>,
      content: <EmptyValue />,
    };
  }

  return {
    title: <>Environments</>,
    content: <>{fco[FeatureViewFCOFields.REALTIME_ENVIRONMENTS].join(',')}</>,
  };
};

const featureStartTimeItem = (fco: FeatureViewFCO) => {
  const materializationStart = fco[FeatureViewFCOFields.MATERIALIZATION_PARAMS].feature_start_timestamp
    ? new Date(fco[FeatureViewFCOFields.MATERIALIZATION_PARAMS].feature_start_timestamp)
    : undefined;

  return {
    title: <>Feature Start Time</>,
    content: <>{materializationStart ? TectonDateTimeFormat(moment(materializationStart)) : <EmptyValue />}</>,
  };
};

const incrementalBackfillItem = (fco: FeatureViewFCO) => {
  const incrementalBackFill =
    fco[FeatureViewFCOFields.FRAMEWORK_VERSION] &&
    fco[FeatureViewFCOFields.FRAMEWORK_VERSION] >= 4 &&
    fco[FeatureViewFCOFields.HAS_INCREMENTAL_BACKFILL];

  return {
    title: <>Incremental Backfill</>,
    content: <EnabledDisabledBadge enabled={!!incrementalBackFill} />,
  };
};

const joinKeysItem = (fco: FeatureViewFCO) => {
  const joinKeysFragment =
    fco[FeatureViewFCOFields.JOIN_KEYS].length === 0 ? (
      <EmptyValue />
    ) : (
      <Monospace>{fco[FeatureViewFCOFields.JOIN_KEYS].join(',')}</Monospace>
    );
  return {
    title: <>Join Keys</>,
    content: joinKeysFragment,
  };
};

const manualTriggerBackfillEndTimeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Manual Trigger Backfill End Time</>,
    content: (
      <>
        {fco[FeatureViewFCOFields.MANUAL_TRIGGER_BACKFILL_END_TIMESTAMP] ? (
          TectonDateTimeFormat(moment(new Date(fco[FeatureViewFCOFields.MANUAL_TRIGGER_BACKFILL_END_TIMESTAMP])))
        ) : (
          <EmptyValue />
        )}
      </>
    ),
  };
};

const materializationRunTimeItem = (fco: FeatureViewFCO) => {
  return fco[FeatureViewFCOFields.JOB_ENVIRONMENT]
    ? {
        title: <>Job Environment</>,
        content: <>{fco[FeatureViewFCOFields.JOB_ENVIRONMENT]}</>,
      }
    : {
        title: <>Materialization Runtime</>,
        content: <>{fco[FeatureViewFCOFields.MATERIALIZATION_RUNTIME] || <EmptyValue />}</>,
      };
};

const onlineBackfillLoadType = (fco: FeatureViewFCO) => {
  const displayMap: Record<OnlineBackfillLoadType, string> = {
    [OnlineBackfillLoadType.ONLINE_BACKFILL_LOAD_TYPE_UNSPECIFIED]: 'n/a',
    [OnlineBackfillLoadType.ONLINE_BACKFILL_LOAD_TYPE_TASK]: 'Task',
    [OnlineBackfillLoadType.ONLINE_BACKFILL_LOAD_TYPE_BULK]: 'Bulk',
    [OnlineBackfillLoadType.ONLINE_BACKFILL_LOAD_TYPE_COMPACTION]: 'Compaction',
  };
  return {
    title: <>Online Backfill Load Type</>,
    content: (
      <>
        {fco[FeatureViewFCOFields.ONLINE_BACKFILL_LOAD_TYPE] ? (
          <>{displayMap[fco[FeatureViewFCOFields.ONLINE_BACKFILL_LOAD_TYPE] as OnlineBackfillLoadType]}</>
        ) : (
          <EmptyValue />
        )}
      </>
    ),
  };
};

const batchCompactionEnabledItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Batch Compaction</>,
    content: <EnabledDisabledBadge enabled={fco[FeatureViewFCOFields.BATCH_COMPACTION_ENABLED]} />,
  };
};

const streamTilingEnabledItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Stream Tiled</>,
    content: <EnabledDisabledBadge enabled={fco[FeatureViewFCOFields.STREAM_TILING_ENABLED] ?? false} />,
  };
};

const materializationStartTimeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Materialization Start Time</>,
    content: (
      <>
        {fco[FeatureViewFCOFields.MATERIALIZATION_START_TIMESTAMP] ? (
          TectonDateTimeFormat(moment(new Date(fco[FeatureViewFCOFields.MATERIALIZATION_START_TIMESTAMP])))
        ) : (
          <EmptyValue />
        )}
      </>
    ),
  };
};

const maxBackfillIntervalItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Max Backfill Interval</>,
    content: (
      <>
        {fco[FeatureViewFCOFields.MAX_BACKFILL_INTERVAL] ? (
          <TimeIntervalDisplay interval={{ seconds: fco[FeatureViewFCOFields.MAX_BACKFILL_INTERVAL] }} />
        ) : (
          <EmptyValue />
        )}
      </>
    ),
  };
};

const maxDataSourceDelayItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Max Data Source Delay</>,
    content: <TimeIntervalDisplay interval={fco[FeatureViewFCOFields.MATERIALIZATION_MAX_SOURCE_DATA_DELAY_SECONDS]} />,
  };
};

const offlineServingItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Offline</>,
    content: <EnabledDisabledBadge enabled={fco[FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED] ?? false} />,
  };
};

const offlineStoreTypeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Staging Table Format</>,
    content: fco[FeatureViewFCOFields.OFFLINE_STORE_TYPE] ? (
      <Monospace>{fco[FeatureViewFCOFields.OFFLINE_STORE_TYPE]}</Monospace>
    ) : (
      <EmptyValue />
    ),
  };
};

const onlineServingItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Online</>,
    content: <EnabledDisabledBadge enabled={fco[FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED] ?? false} />,
  };
};

const onlineStoreTypeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Store Type</>,
    content: fco[FeatureViewFCOFields.ONLINE_STORE_TYPE] ? (
      <Monospace>{fco[FeatureViewFCOFields.ONLINE_STORE_TYPE]}</Monospace>
    ) : (
      <EmptyValue />
    ),
  };
};

const relatedEntitiesItem = (
  fco: FeatureViewFCO,
  relatedFcos: { entities: EntityFCO[] } | undefined,
  workspace?: string
) => {
  let entityFragments: React.ReactNode[] | undefined = undefined;

  entityFragments = relatedFcos?.entities?.map((entity: EntityFCO) => {
    return (
      <>
        <Link to={`/repo/${workspace}/entities/${entity.name}/overview`}>
          <TectonFCOIconAndName name={entity.name ?? ''} type={FCOType.ENTITY} />
        </Link>
      </>
    );
  });

  const fragmentArray: React.ReactNode[] = [];
  const interleave = (arr: React.ReactNode[], fragment: React.ReactNode) =>
    fragmentArray.concat(...arr.map((n) => [n, fragment])).slice(0, -1);

  if (!entityFragments) {
    return {
      title: <>Entities</>,
      content: <EmptyValue />,
    };
  }
  return {
    title: <>Entities</>,
    content: (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {interleave(entityFragments, <span style={{ marginRight: '1em' }}>, </span>).map((fragment) => fragment)}
      </div>
    ),
  };
};

const requestDataSourceKeysItem = (fco: FeatureViewFCO) => {
  if (fco[FeatureViewFCOFields.ON_DEMAND_REQUEST_DATA_SOURCE_KEYS].length === 0) {
    return {
      title: <>Request Data Source Keys</>,
      content: <EmptyValue />,
    };
  }

  return {
    title: <>Request Data Source Keys</>,
    content: <Monospace>{fco[FeatureViewFCOFields.ON_DEMAND_REQUEST_DATA_SOURCE_KEYS].join(', ')}</Monospace>,
  };
};

const servingTTLItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Serving TTL</>,
    content: fco[FeatureViewFCOFields.SERVING_TTL] ? <>{fco[FeatureViewFCOFields.SERVING_TTL]}</> : <EmptyValue />,
  };
};

const skipDefaultExpectationsItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Skip Default Expectations</>,
    content: <EnabledDisabledBadge enabled={fco[FeatureViewFCOFields.SKIP_DEFAULT_EXPECTATIONS] ?? false} />,
  };
};

const streamProcessingModeItem = (fco: FeatureViewFCO) => {
  const mode = fco[FeatureViewFCOFields.IS_CONTINUOUS] ? 'Continuous' : 'Time Interval';
  return {
    title: <>Stream Processing Mode</>,
    content: <>{mode}</>,
  };
};

const timestampFieldItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Timestamp Field</>,
    content: <Monospace>{fco[FeatureViewFCOFields.TIME_KEY]}</Monospace>,
  };
};

const typeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Feature View Type</>,
    content: fco[FeatureViewFCOFields.FEATURE_VIEW_TYPE],
  };
};

const cacheMaxAgeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Cache Max Age</>,
    content: <TimeIntervalDisplay interval={fco[FeatureViewFCOFields.CACHE_MAX_AGE_SECONDS]} />,
  };
};

const freshnessMonitoringEnabledItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Freshness Monitoring Enabled</>,
    content: <EnabledDisabledBadge enabled={fco[FeatureViewFCOFields.IS_MATERIALIZATION_ENABLED] ?? true} />,
  };
};

const expectedFreshnessItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Expected Freshness</>,
    content: fco[FeatureViewFCOFields.EXPECTED_FRESHNESS] ? (
      fco[FeatureViewFCOFields.EXPECTED_FRESHNESS]
    ) : (
      <EmptyValue />
    ),
  };
};

const primaryEndpointItem = (fco: FeatureViewFCO) => {
  if (fco[FeatureViewFCOFields.ONLINE_STORE_TYPE] !== OnlineStoreType.REDIS) {
    return { title: 'Primary Endpoint', content: <EmptyValue /> };
  }

  return {
    title: <>Primary Endpoint</>,
    content: fco[FeatureViewFCOFields.PRIMARY_ENDPOINT] ? fco[FeatureViewFCOFields.PRIMARY_ENDPOINT] : <EmptyValue />,
  };
};

const publishFullFeaturesItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Publish Full Features</>,
    content: fco[FeatureViewFCOFields.PUBLISH_FULL_FEATURES] ? (
      <EnabledDisabledBadge enabled={fco[FeatureViewFCOFields.PUBLISH_FULL_FEATURES] ?? false} />
    ) : (
      <EmptyValue />
    ),
  };
};

const publishStarttimeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Publish Start Time</>,
    content: fco[FeatureViewFCOFields.PUBLISH_START_TIME] ? (
      TectonDateTimeFormat(moment(fco[FeatureViewFCOFields.PUBLISH_START_TIME]))
    ) : (
      <EmptyValue />
    ),
  };
};

type ConfigFunctionType =
  | ((fco: FeatureViewFCO) => TectonFCOListItem)
  | ((
      fco: FeatureViewFCO,
      relatedFCOs: { entities: EntityFCO[] } | undefined,
      workspace?: string
    ) => TectonFCOListItem);

const ConfigCardItemMap: Record<ConfigCardItemType, ConfigFunctionType> = {
  [ConfigCardItemType.TYPE]: typeItem,
  [ConfigCardItemType.TIMESTAMP_FIELD]: timestampFieldItem,
  [ConfigCardItemType.STREAM_PROCESSING_MODE]: streamProcessingModeItem,
  [ConfigCardItemType.SKIP_DEFAULT_EXPECTATIONS]: skipDefaultExpectationsItem,
  [ConfigCardItemType.SERVING_TTL]: servingTTLItem,
  [ConfigCardItemType.REQUEST_DATA_SOURCE_KEYS]: requestDataSourceKeysItem,
  [ConfigCardItemType.ONLINE_STORE_TYPE]: onlineStoreTypeItem,
  [ConfigCardItemType.ONLINE_MATERIALIZATION]: onlineServingItem,
  [ConfigCardItemType.OFFLINE_STORE_TYPE]: offlineStoreTypeItem,
  [ConfigCardItemType.OFFLINE_MATERIALIZATION]: offlineServingItem,
  [ConfigCardItemType.MAX_DATA_SOURCE_DELAY]: maxDataSourceDelayItem,
  [ConfigCardItemType.MAX_BACKFILL_INTERVAL]: maxBackfillIntervalItem,
  [ConfigCardItemType.MATERIALIZATION_START_TIME]: materializationStartTimeItem,
  [ConfigCardItemType.MATERIALIZATION_RUNTIME]: materializationRunTimeItem,
  [ConfigCardItemType.ONLINE_BACKFILL_LOAD_TYPE]: onlineBackfillLoadType,
  [ConfigCardItemType.BATCH_COMPACTION_ENABLED]: batchCompactionEnabledItem,
  [ConfigCardItemType.STREAM_TILING_ENABLED]: streamTilingEnabledItem,
  [ConfigCardItemType.MANUAL_TRIGGER_BACKFILL_END_TIME]: manualTriggerBackfillEndTimeItem,
  [ConfigCardItemType.JOIN_KEYS]: joinKeysItem,
  [ConfigCardItemType.INCREMENTAL_BACKFILL]: incrementalBackfillItem,
  [ConfigCardItemType.FRESHNESS_MONITORING_ENABLED]: freshnessMonitoringEnabledItem,
  [ConfigCardItemType.FEATURE_START_TIME]: featureStartTimeItem,
  [ConfigCardItemType.EXPECTED_FRESHNESS]: expectedFreshnessItem,
  [ConfigCardItemType.ENVIRONMENTS]: environmentsConfigItem,
  [ConfigCardItemType.ENTITIES]: relatedEntitiesItem,
  [ConfigCardItemType.DATA_QUALITY_ENABLED]: dataQualityItem,
  [ConfigCardItemType.CACHE_MAX_AGE]: cacheMaxAgeItem,
  [ConfigCardItemType.BATCH_TRIGGER]: batchTriggerItem,
  [ConfigCardItemType.BATCH_SCHEDULE]: batchScheduleItem,
  [ConfigCardItemType.ALERT_EMAIL]: alertEmailItem,
  [ConfigCardItemType.AGGREGATION_SECONDARY_KEY]: aggregationSecondaryKeyItem,
  [ConfigCardItemType.AGGREGATION_INTERVAL]: aggregationIntervalItem,
  [ConfigCardItemType.PRIMARY_ENDPOINT]: primaryEndpointItem,
  [ConfigCardItemType.PUBLISH_FULL_FEATURES]: publishFullFeaturesItem,
  [ConfigCardItemType.PUBLISH_START_TIME]: publishStarttimeItem,
};

interface FeatureViewConfigurationCardProps {
  fco: FeatureViewFCO;
  relatedFCOs?: { entities: EntityFCO[] };
  title: string;
  items: ConfigCardItemType[];
}

interface FeatureViewStorageCardProps {
  fco: FeatureViewFCO;
  onlineItems: ConfigCardItemType[];
  offlineItems: ConfigCardItemType[];
}

const CardOuter = styled.div`
  padding-bottom: ${({ theme }) => theme.padding.l};
`;

const CardInnerHeading = styled.div`
  border-bottom: 1px solid var(--Data-Grid-Vertical-border, #edf0f5);
`;

export const MaterializationStorageCard: FC<FeatureViewStorageCardProps> = ({ fco, onlineItems, offlineItems }) => {
  const { data } = useGetGlobalsForWebUi();

  const isDynamoDB = fco[FeatureViewFCOFields.ONLINE_STORE_TYPE] === OnlineStoreType.DYNAMO;
  const primaryRegion = data?.key_values?.CLUSTER_REGION || '';
  const replicatedRegions = fco[FeatureViewFCOFields.REPLICATED_REGIONS]
    ? fco[FeatureViewFCOFields.REPLICATED_REGIONS].join(', ')
    : '';

  const onlineList = onlineItems.map((itemKey) => ConfigCardItemMap[itemKey](fco, undefined));
  const offlineList = offlineItems.map((itemKey) => ConfigCardItemMap[itemKey](fco, undefined));

  if (isDynamoDB) {
    onlineList.push({ title: 'Primary Region', content: primaryRegion });
    onlineList.push({ title: 'Replicated Regions', content: replicatedRegions });
  }

  return (
    <Card title={'Storage Configuration'}>
      <CardOuter>
        <CardInnerHeading>Online</CardInnerHeading>
      </CardOuter>
      <CardTable>
        <KeyValueList items={onlineList} />
      </CardTable>
      <Spacer size="xl" />
      <CardOuter>
        <CardInnerHeading>Offline</CardInnerHeading>
      </CardOuter>
      <CardTable>
        <KeyValueList items={offlineList} />
      </CardTable>
    </Card>
  );
};

const FeatureViewConfigurationCard: FC<FeatureViewConfigurationCardProps> = ({ fco, relatedFCOs, title, items }) => {
  const { workspace } = useUserSettings();
  let itemsOut = items ?? [];

  if (fco?.BATCH_COMPUTE_MODE === 'BATCH_COMPUTE_MODE_RIFT') {
    itemsOut = items.filter(
      (item) =>
        item !== ConfigCardItemType.DATA_QUALITY_ENABLED && item !== ConfigCardItemType.SKIP_DEFAULT_EXPECTATIONS
    );
  }

  const itemList = itemsOut.map((itemKey) => {
    return ConfigCardItemMap[itemKey](fco, relatedFCOs, workspace);
  });

  return <TectonFCOCard title={title} items={itemList} />;
};

export default FeatureViewConfigurationCard;
