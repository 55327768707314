import React, { FC, useState } from 'react';
import _debounce from 'lodash/debounce';
import * as yup from 'yup';

// @tecton
import { FlexItem, Text, FormInput } from '@tecton/ComponentRedesign';
import { FlexGroupWrapper } from '../../@tecton/ComponentRedesign/StyledComponents';

interface InviteNewUserProps {
  hasDuplicateEmail?: boolean;
  onValidEmailChange: (email: string | undefined) => void;
}

const InviteNewUser: FC<InviteNewUserProps> = ({ hasDuplicateEmail, onValidEmailChange }) => {
  const emailSchema = yup.string().email();
  // We keep the state here to prevent the parent from rerendering
  const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>(false);

  const updateIsEmailInvalidChange = async (email: string) => {
    try {
      // Why did they make this into a promise LoL? When it throws and exception, it's false.
      await emailSchema.validate(email);
      setIsEmailInvalid(false);
      onValidEmailChange(email);
    } catch {
      setIsEmailInvalid(true);
      onValidEmailChange(undefined);
    }
  };

  const onChange = (event: any) => {
    /*
      This call is debounced so we have less rerenders from the parent.
      Passing a setState function to a component is not a good practice since it forces the parent to rerender
    */
    const email = event?.target?.value?.trim().toLowerCase();
    updateIsEmailInvalidChange(email);
    setEmail(email);
  };

  const debounceOnChange = _debounce(onChange, 250);

  let errorText = '';
  if (hasDuplicateEmail) {
    errorText = `This email "${email}" already exists.`;
  } else if (isEmailInvalid) {
    errorText = `Please enter a valid email address.`;
  }

  return (
    <>
      <FlexGroupWrapper gap="xl" direction="column">
        <FlexItem grow={true}>
          <Text>Enter email address to invite new user to Tecton</Text>
        </FlexItem>
        <FlexItem grow={true}>
          <FormInput
            errorText={errorText}
            label={'Email Address'}
            placeholder=""
            error={isEmailInvalid || hasDuplicateEmail}
            onChange={debounceOnChange}
            fullWidth={true}
          />
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default InviteNewUser;
