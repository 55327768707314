import React, { FC, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import { Link } from 'react-router-dom';
import { Routes } from '../../../core/routes';

// generated code
import { User } from '../../../api/gql/graphql';

// @tecton
import {
  SearchAndActionButton,
  Text,
  EmptyPrompt,
  ButtonIcon,
  Tooltip,
  EuiBasicTableColumn,
  Table,
  FlexGroup,
  FlexItem,
  IconTip,
  FlexGroupWrapper,
  AvatarAndName,
} from '@tecton/ComponentRedesign';

// tecton icons
import { ReactComponent as Trash } from '@svg/trash.svg';
import { ReactComponent as QuestionInCircle } from '@svg/question-in-circle.svg';

export interface ACLGroupUserListProps {
  assignedUsers: User[];
  isAdminView: boolean;
  onDeleteUser: (user: User) => void;
  onAddUser: () => void;
  isLoading?: boolean;
}

// Errors and loading state should handler out side if this components.
const ACLGroupUserList: FC<ACLGroupUserListProps> = ({
  assignedUsers,
  isAdminView,
  onDeleteUser,
  onAddUser,
  isLoading,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const filteredWorkspaceRole = searchText
    ? assignedUsers?.filter((user: User) => {
        return user.loginEmail?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : assignedUsers;

  const sortedUsers = isLoading ? undefined : _sortBy(filteredWorkspaceRole, ['loginEmail']);

  const columns: EuiBasicTableColumn<User>[] = [
    {
      name: 'User',
      render: (user: User) => {
        return (
          <>
            <Link to={`${Routes.accountsAndAccessUsers}/${user.loginEmail}/workspaces`}>
              <AvatarAndName name={user.loginEmail ?? ''} />
            </Link>
          </>
        );
      },
    },

    {
      name: (
        <>
          <FlexGroup justifyContent="center" alignItems="center" direction="row" gutterSize="none">
            <FlexItem>Membership Type</FlexItem>
            <FlexItem>
              <IconTip
                content={`How the user was assigned to the group. Either manually, or via Identity Provider attributes.`}
                type={QuestionInCircle}
              />
            </FlexItem>
          </FlexGroup>
        </>
      ),
      render: (user: User) => {
        return <Text>{user?.membershipType}</Text>;
      },
    },
    ...(isAdminView
      ? [
          {
            name: 'Actions',
            actions: [
              {
                name: 'remove',
                isPrimary: true,
                render: (user: User) => {
                  return (
                    <>
                      <Tooltip
                        content={`Remove ${user?.loginEmail} from Group.`}
                        trigger={
                          <>
                            <ButtonIcon
                              variant="dangerousAction"
                              iconType={Trash}
                              color="ghost"
                              size="xs"
                              onClick={() => {
                                onDeleteUser(user);
                              }}
                            />
                          </>
                        }
                      />
                    </>
                  );
                },
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        <SearchAndActionButton
          searchPlaceHolder={'Search for User'}
          actionButtonLabel={'Add User To Group'}
          actionButtonVariant={isAdminView ? 'primaryAction' : 'disabledAction'}
          onActionButtonClick={() => {
            onAddUser();
          }}
          onSearchInputChange={function (search: string): void {
            setSearchText(search ?? undefined);
          }}
        />
      </FlexItem>
      <FlexItem>
        {/* The list will never be empty */}
        <Table
          items={sortedUsers ?? []}
          columns={columns}
          emptyPrompt={
            <>
              {assignedUsers.length === 0 && (
                <EmptyPrompt
                  title={<>This Group is Empty</>}
                  body={
                    <>
                      <p>To add a user to this group click the 'Add User to Group' button in the upper right corner.</p>
                    </>
                  }
                />
              )}
              {assignedUsers.length > 0 && (
                <EmptyPrompt
                  title={<>No Results</>}
                  body={
                    <>
                      <p>The search filter returned no results.</p>
                    </>
                  }
                />
              )}
            </>
          }
        />
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default ACLGroupUserList;
