import styled from '@emotion/styled';
import { Icons, IconTypes, Popover } from '@tecton/ComponentRedesign';
import { TectonTimeComboSelectorButtonProps } from '@tecton/ComponentRedesign/lib/TimeComboSelector';
import { FC, useState } from 'react';
import FeatureServerGroupLists, { FeatureServerGroupListsProps } from './FeatureServerGroupLists';

const ButtonWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  gap: ${({ theme }) => theme.gap.s};
  padding: ${({ theme }) => theme.padding.xs} ${({ theme }) => theme.padding.s};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: ${({ theme }) => theme.border.thin};
  background: ${({ theme }) => theme.colors.emptyShade};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.disabledText : theme.colors.text)};
`;

const LabelWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
`;

export const SelectorButton: FC<TectonTimeComboSelectorButtonProps> = ({ label, onClick, disabled }) => {
  return (
    <ButtonWrapper disabled={disabled} onClick={onClick} data-testid="combo-selector-button">
      {Icons[IconTypes.DATABASE]}
      <LabelWrapper>{label}</LabelWrapper>
      {Icons[IconTypes.CHEVRON_DOWN]}
    </ButtonWrapper>
  );
};

const FeatureServerGroupSelector: FC<FeatureServerGroupListsProps> = ({
  featureServerGroups,
  transformationServerGroups,
  selectedServerGroup,
  onServerGroupSelected,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Popover
      data-testid="feature-service-group-selector"
      button={
        <SelectorButton
          label={selectedServerGroup?.name ?? 'Default Feature Server'}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
      }
      maxWidth="600px"
      isOpen={isOpen}
      body={
        <FeatureServerGroupLists
          featureServerGroups={featureServerGroups}
          transformationServerGroups={transformationServerGroups}
          selectedServerGroup={selectedServerGroup}
          onServerGroupSelected={onServerGroupSelected}
        />
      }
      closePopover={() => {
        setIsOpen(false);
      }}
    />
  );
};

export default FeatureServerGroupSelector;
