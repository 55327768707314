import styled from '@emotion/styled';
import moment from 'moment-timezone';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { useUserSettings } from '../../context/UserSettingsContext';

import DataflowContainer from '@tecton/ComponentRedesign/Dataflow/DataflowContainer';
import {
  AnyFCO,
  DataSourceFCO,
  FCOFields,
  FCOType,
  FeatureViewFCO,
  FeatureViewFCOFields,
  TemporalAggregateFeatureForFCO,
  TransformationFCO,
  TransformationFCOFields,
  WorkspaceFCOContainer,
} from '../../../core/types/fcoTypes';

import {
  ButtonEmpty,
  Card,
  EmptyValue,
  FCOIconAndName,
  FlexGroupWrapper,
  FlexItem,
  Icons,
  IconTypes,
  Table,
  Tooltip,
} from '@tecton/ComponentRedesign';

import { dataflowSpecSelector, idFormatter } from '@tecton/ComponentRedesign/Dataflow/workspaceDataflowSpecSelector';
import TectonCode from '@tecton/ComponentRedesign/lib/Code';
import TectonFlyout from '@tecton/ComponentRedesign/lib/Flyout';
import { TectonDateTimeFormat } from '@tecton/ComponentRedesign/utils';

import CodeDocumentIcon from '@svg/code-document.svg';
import { ColumnLayout } from '../../@tecton/ComponentRedesign/';

interface FeatureViewDataflowContainerProps {
  fco: FeatureViewFCO;
  workspaceData: WorkspaceFCOContainer;
  idToFcoMap: Record<string, AnyFCO>;
}

const DataflowContainerWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.padding.l};
  height: 50vh;
`;

const WINDOW_AGGREGATION = 'Window Aggregations';

const FeatureViewDataflowContainer: FC<FeatureViewDataflowContainerProps> = ({ fco, workspaceData, idToFcoMap }) => {
  const { workspace } = useUserSettings();
  const [openFlyoutId, setIsFlyoutOpen] = useState<string | null | undefined>();

  const data = dataflowSpecSelector(workspaceData, fco.name ?? '', {
    id: idFormatter(fco),
    type: FCOType.FEATURE_VIEW,
  });

  const sources = fco[FeatureViewFCOFields.DATA_SOURCE_IDS].map((id) => idToFcoMap[id] as DataSourceFCO);
  const transformations = fco[FeatureViewFCOFields.ALL_TRANSFORMATIONS].map(
    (id) => idToFcoMap[id] as TransformationFCO
  );

  if (fco[FeatureViewFCOFields.TEMPORAL_AGGREGATE_FEATURES].length > 0) {
    transformations.unshift({
      name: WINDOW_AGGREGATION,
      description: '',
      createdDate: moment(fco[FCOFields.CREATED_DATE]).toDate(),
      owner: fco.owner,
      code:
        fco.temporalAggregateFeatures
          ?.map((i: TemporalAggregateFeatureForFCO) => {
            return `${i.aggregation} over ${i.aggregationWindow} as ${i.name}`;
          })
          .join('\n') ?? '',
      [TransformationFCOFields.MODE]: undefined,
      [TransformationFCOFields.DEPENDENT_FEATURE_VIEWS]: [],
      [TransformationFCOFields.DEPENDENT_FEATURE_SERVICES]: [],
      [FCOFields.ID]: fco[FCOFields.ID],
      [FCOFields.TRUNCATED_DESCRIPTION]: '',
      [FCOFields.TAGS]: [],
      [FCOFields.FCO_TYPE]: FCOType.DATA_SOURCE,
      [FCOFields.LAST_MODIFIED_BY]: undefined,
      [FCOFields.LAST_MODIFIED_DATE]: undefined,
      [FCOFields.WORKSPACE]: undefined,
      [FCOFields.SOURCE_FILE_NAME]: undefined,
      [FCOFields.PREVENT_DESTROY]: undefined,
    });
  }

  const sourceColumns = [
    {
      name: 'Source',
      render: (source: DataSourceFCO) => (
        <Link to={`/repo/${workspace}/data-sources/${source.name}/overview`}>
          <FCOIconAndName name={source.name ?? ''} type={FCOType.DATA_SOURCE} description={source.description} />
        </Link>
      ),
    },
    {
      name: 'Created',
      render: (source: DataSourceFCO) =>
        source[FCOFields.CREATED_DATE] ? TectonDateTimeFormat(moment(source[FCOFields.CREATED_DATE])) : <EmptyValue />,
    },
  ];

  const transformationColumns = [
    {
      name: 'Source',
      render: (transformation: TransformationFCO) => {
        if (transformation[FCOFields.NAME] === WINDOW_AGGREGATION) {
          return (
            <Tooltip
              title={`${transformation.name}`}
              content={''}
              data-testid="window-aggregation-tooltip-content"
              trigger={
                <>
                  <FlexGroupWrapper gap="xs" direction={'row'} justifyContent="center" alignItems="center">
                    <FlexItem grow={false}>{Icons[IconTypes.AGGREGATION]} </FlexItem>
                    <FlexItem> {WINDOW_AGGREGATION} </FlexItem>
                  </FlexGroupWrapper>
                </>
              }
            />
          );
        }

        return (
          <Link to={`/repo/${workspace}/transformations/${transformation.name}/overview`}>
            <FCOIconAndName
              name={transformation.name ?? ''}
              type={FCOType.TRANSFORMATION}
              description={transformation.description}
            />
          </Link>
        );
      },
    },
    {
      name: 'Definition',
      render: (transformation: TransformationFCO) => (
        <>
          <ButtonEmpty
            onClick={() => {
              setIsFlyoutOpen(transformation?.id);
            }}
            iconType={CodeDocumentIcon}
          >
            View Definition
          </ButtonEmpty>
          {openFlyoutId === transformation?.id && (
            <TectonFlyout
              title={transformation[FCOFields.NAME]}
              onClose={() => {
                setIsFlyoutOpen(null);
              }}
            >
              <TectonCode code={transformation[TransformationFCOFields.CODE] ?? ''} />
            </TectonFlyout>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <DataflowContainerWrapper>
        <DataflowContainer isFcoLevelDiagram={true} data={data} />
      </DataflowContainerWrapper>
      <ColumnLayout>
        <Card title="Sources">
          <Table items={sources} columns={sourceColumns} />
        </Card>
        <Card title="Transformations">
          <Table items={transformations} columns={transformationColumns} />
        </Card>
      </ColumnLayout>
    </>
  );
};

export default FeatureViewDataflowContainer;
