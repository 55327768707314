import _ from 'lodash';
import { FC } from 'react';
import { usePrometheus } from '../../../feature/query';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import FeatureViewHealthTable from './FeatureViewHealthTable';

// @tecton
import { Card, Loading } from '@tecton/ComponentRedesign';
import { getFeatureHealthResults } from '@tecton/ComponentRedesign/Charts/graph-util';
import { FlexGroup } from '../../@tecton/ComponentRedesign/lib/v1';

interface FeatureViewHealthDrillDownsProps {
  monitoringDateRange: MonitoringDateRange;
}

const FeatureViewHealthDrillDowns: FC<FeatureViewHealthDrillDownsProps> = ({ monitoringDateRange }) => {
  const queries = [
    {
      label: 'Stale IDs',
      query: `count by (tecton_cluster, feature_package_id) (ALERTS{alertstate='firing', alertname='FeatureViewHighStaleness', tecton_cluster=~'.*', aws_region=~'.*'})`,
    },
  ];

  const state = usePrometheus(queries, monitoringDateRange);
  if (state.isLoading) {
    return <Loading size={'xxl'} />;
  }

  const result = _.get(state, 'data');
  const healthResults = getFeatureHealthResults(result ? result : []);
  const healthResultsLength = healthResults.length;

  return (
    <Card title={healthResultsLength > 0 ? `Stale Feature Views (${healthResultsLength})` : `Stale Feature Views`}>
      <FlexGroup padding="0" justifyContent="center" alignItems="center">
        <FeatureViewHealthTable healthResults={healthResults} />
      </FlexGroup>
    </Card>
  );
};

export default FeatureViewHealthDrillDowns;
